.home-page{
  height: 100%;
  .navbar{
    //background-color: red;
    width: 100%;
    padding: 2rem 5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    background-color: rgba(18, 22, 55, 0.5);
    //box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    z-index: 100;

    .logo {
      height: 4.2rem;
      width: 4.2rem;
      background-color: white;
      padding: 0.7rem;
      border-radius: 2.1rem;
      //border: 3px solid var(--lightBlue);

      img {
        height: 100%;
        width: 100%;
        object-fit: scale-down;
      }
    }

    .links{
      display: flex;
      flex-direction: row;
      text-align: end;
      align-content: center;
      //background-color: orange;
      height: fit-content;
      width: fit-content;
      padding: 0;
      gap: 2rem;

      a {
        font-size: 1.4rem;
        font-weight: 600;
        color: var(--lightGray);
        //background-color: green;
        padding: 1rem;
      }
      .resumeButton {
        border: 3px solid var(--lightBlue);
        border-radius: .3rem;
      }
      a:hover {
        color: var(--green);
      }
      .resumeButton:hover{
        color: var(--lightBlue);
        background-color: rgba(67, 219, 255,.2);
      }
    }
  }

  .collapseNavbar{
    display: none;
  }

  .mobileNav{
    height: 100%;
    width: 70%;
    background-color: rgba(18, 22, 55, 0.5);
    //box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    z-index: 100;
    right: -70%;
    position: fixed;
    transition: right 0.3s ease-in-out;
    padding: 6rem 2rem 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .links {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      a{
        color: var(--lightGray);
        font-size: 2.4rem;
        font-weight: 600;
        padding: 1rem;
      }
      .resumeButton {
        border: 3px solid var(--lightBlue);
        border-radius: .3rem;
        width: fit-content;
      }
      a:hover {
        color: var(--green);
      }
      .resumeButton:hover{
        color: var(--lightBlue);
        background-color: rgba(67, 219, 255,.2);
      }
    }

    .socials{
      display: flex;
      flex-direction: row;
      width: 100%;
      max-width: 30rem;

      justify-content: space-between;
      a {
        padding: 1rem;
        color: var(--gray);
        font-size: 3.4rem;
        transition: color .1s ease-in-out, transform .2s ease-in-out, font-size .1s ease-in-out;
      }
      a:hover {
        color: var(--lightBlue);
        transform: translateY(-4px);
        font-size: 2.5rem;
      }
    }
  }
  .active{
    right: 0;
  }

  .container {
    max-width: 100rem;
    margin: 0 auto;
    min-height: 100%;
    //padding: 0 9rem;
    //background-color:orange;
  }

  .full {
    min-height: 100%;
  }

  #home{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;

    p{
      max-width: 50rem;
    }
    h1{
      font-size: 7rem;
    }
  }

  #about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4rem;
    height: 100%;
    width: 100%;

    h1{
      font-size: 3rem;
      border-bottom: .3rem solid var(--lightBlue);
      width: fit-content;
    }

    .content {
      display: flex;
      //align-items: center;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      gap: 4rem;

      p{
        max-width: 55rem;

      }

      .img{
        position: relative;
        height: fit-content;
        width: fit-content;
      }

      img {
        max-width: 31.4rem;
        //min-width: 100%;
        transition: filter 0.5s ease-in-out;
      }
      .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 1;
        transition: .5s ease;
        background-color: 	rgba(67, 219, 255,0.6);
        box-shadow: 1rem 1rem 0px var(--lightBlue);
      }
      .img:hover .overlay{
        opacity: 0;
      }
      .overlay:hover{
        box-shadow: 0 0 0px var(--lightBlue);
      }
    }
  }

  #experience{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    width: fit-content;
    text-align: left;

    h1{
      font-size: 3rem;
      border-bottom: .3rem solid var(--lightBlue);
      width: fit-content;
      justify-content: flex-start;
    }

    .workExperience{
      display: flex;
      flex-direction: column;
      gap: 4rem;

      .job{
        display: flex;
        flex-direction: row;
        gap: 6rem;

        h1{
          font-size: 2rem;
          border: none;
          width: 8rem;
        }

        .content{
          display: flex;
          flex-direction: column;
          gap: 2rem;

          h1{
            font-size: 2rem;
            border: none;
            width: fit-content;
          }

          .title{
            display: flex;
            flex-direction: row;
            gap: 1rem;
          }

          p{
            max-width: 60rem;
          }
        }
      }
    }
  }

  #latest{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4rem;
    min-height: fit-content;

    h1{
      font-size: 3rem;
      border-bottom: .3rem solid var(--lightBlue);
      width: fit-content;
      justify-content: flex-start;
    }

    .project{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 4rem;

      img{
        max-width: 55rem;
        box-shadow: none;
        transition: box-shadow 0.3s ease-in-out, scale 0.3s ease-in-out;
      }
      img:hover{
        box-shadow: 1rem 1rem 0px var(--lightBlue);
        scale: 1.1;
      }

      .details{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        max-width: 42%;

        h1{
          border-bottom: none;
          font-size: 2.4rem;
        }

        .link{
            padding: 1rem;
            color: var(--gray);
            font-size: 2.4rem;
            transition: color .1s ease-in-out, font-size .1s ease-in-out;
        }
        .link:hover {
          color: var(--lightBlue);

        }
      }
    }
  }

  #contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6rem;
    width: 50rem;

    h1{
      font-size: 4rem;
      border-bottom: .3rem solid var(--lightBlue);
      width: fit-content;
      justify-content: flex-start;
    }
    p{
      text-align: center;
    }

    .contactInfo{
      border: 3px solid var(--lightBlue);
      border-radius: .3rem;
      padding: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 42rem;

      .icon{
        display: flex;
        font-size: 2.4rem;
        margin: 0 2rem 0 1rem;
      }

      p{
        text-align: left;
      }
    }

    .button {
      font-size: 2.4rem;
      border: 3px solid var(--lightBlue);
      border-radius: .3rem;
      padding: 1rem;
    }
    .button:hover{
      color: var(--lightBlue);
      background-color: rgba(67, 219, 255,.2);
    }
  }

  .footer{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 3rem;
    left: 4rem;

    gap: 1rem;
    a {
      padding: 1rem;
      color: var(--gray);
      font-size: 2.4rem;
      transition: color .1s ease-in-out, transform .2s ease-in-out, font-size .1s ease-in-out;
    }
    a:hover {
      color: var(--lightBlue);
      transform: translateY(-4px);
      font-size: 2.5rem;
    }
  }
}

@media only screen and (max-width: 1250px) {
  .home-page{
    .container {
      padding: 0 10rem;
    }

    #about{
      .content{
        justify-content: center;
      }
    }

    #latest{
      .project{
        justify-content: center;
        .details{
          max-width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width:765px) {
  .home-page{
    .navbar{
      display: none;
    }
    .collapseNavbar{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 2rem 3rem;
      position: fixed;
      width: 100%;
      background-color: rgba(18, 22, 55, 0.5);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      z-index: 100;

      .logo {
        height: 4.2rem;
        width: 4.2rem;
        background-color: white;
        padding: 0.7rem;
        border-radius: 2.1rem;
        //border: 3px solid var(--lightBlue);

        img {
          height: 100%;
          width: 100%;
          object-fit: scale-down;
        }
      }

      .bars{
        color: var(--lightGray);
        background-color: rgba(255, 255, 255, 0.0);
        border: none;
        padding: 0;
        margin: 0;
        font-size: 3rem;
      }
    }

    #sidebar{
      display: none;
    }
  }
}

@media only screen and (max-width:570px) {
  .home-page{
    width: 100%;
    #about, #experience{
      margin-bottom: 8rem;
    }

    .container{
      padding: 2rem
    }

    #latest{
      .project{
        img{
          max-width: 100%;
        }
      }
    }

    #contact{
      width: 100%;

      .contactInfo{
        width: 100%;

        .icon{
          display: flex;
          font-size: 2.4rem;
          margin: 0 2rem 0 1rem;
        }
      }
      p{
        font-size: 1.6rem;
      }
    }
  }
}
