:root {
  --indigo: #121637;
  --lightGray: #f6f7fb;
  --gray: #a3aac0;
  --green: #6ee9a9;
  --lightBlue: #43dbff;
}
*:hover{
  -webkit-transition: color .3s ease-in-out, background-color .3s ease-in-out;
  -moz-transition: color .3s ease-in-out, background-color .3s ease-in-out;
  -o-transition: color .3s ease-in-out, background-color .3s ease-in-out;
  transition: color .3s ease-in-out, background-color .5s ease-in-out;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--lightBlue) var(--indigo);
}
*::-webkit-scrollbar {
  width: 7px;
}
*::-webkit-scrollbar-track {
  background: var(--indigo);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--lightBlue);
  border-radius: 7px;
  border: none;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}
html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

html,
body,
#root,
.App {
  height: 100%;
  color: var(--lightGray);
  background-color: var(--indigo);
}
*,
::before,
::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

p {
  font-size: 2rem;
  font-family: 'Roboto', sans-serif;
  line-height: 2.7rem;
  color: var(--gray);
}
a {
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}
a,p,h1,h2,h3,h4,h5,h6{
  padding: 0;
  margin: 0;
}

// webfonts
.fontCode {
  font-family: 'Source Code Pro', monospace;
}
.fontNoto {
  font-family: 'Noto Sans', sans-serif;
}
.fontRoboto{
  font-family: 'Roboto', sans-serif;
}
.fontOpen{
  font-family: 'Open Sans', sans-serif;
}

// colors
.green {
  color: var(--green);
}
.indigo {
  color: var(--indigo);
}
.gray {
  color: var(--gray);
}
.lightGray {
  color: var(--lightGray);
}
.lightBlue {
  color: var(--lightBlue);
}

@media only screen and (max-width: 600px) {
  body {

  }
}

